import {
	Form,
	Link,
	Outlet,
	useRouteLoaderData,
	useSubmit,
} from '@remix-run/react';
import { useRef } from 'react';
import { Logo } from '#app/components/logo/logo.tsx';
import { Button } from '#app/components/ui/button.tsx';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
import { type loader as rootLoader } from '#app/root.tsx';
import { useOptionalUser, useUser } from '#app/utils/user.ts';
import { ThemeSwitch } from '../resources+/theme-switch';

export default function MarketingLayout() {
	const data = useRouteLoaderData<typeof rootLoader>('root');
	const user = useOptionalUser();

	let dashboard;
	if (user && user.roles.some((role) => role.name !== 'user')) {
		dashboard = (
			<Button asChild variant="default" size="lg">
				<Link to="/admin">Admin</Link>
			</Button>
		);
	} else {
		dashboard = (
			<Button asChild variant="default" size="lg">
				<Link to="/manage">Manage</Link>
			</Button>
		);
	}

	return (
		<>
			<div className="flex min-h-screen flex-col justify-between">
				<header className="container py-6">
					<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
						<Link to="/" className="flex items-center gap-4">
							<Logo variant="dark" themed size="md" />
						</Link>
						<div className="flex items-center gap-10">
							{user ? (
								<>
									{dashboard}
									<UserDropdown />
								</>
							) : (
								<Button asChild variant="default" size="lg">
									<Link to="/login">Log In</Link>
								</Button>
							)}
						</div>
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>

				<footer className="container flex justify-between pb-5">
					<p className="align-center flex text-center text-sm text-gray-400">
						© 2024 Company Run. All rights reserved.
					</p>
					<ThemeSwitch
						userPreference={data?.requestInfo.userPrefs.theme}
					/>
				</footer>
			</div>
		</>
	);
}

function UserDropdown() {
	const user = useUser();
	const submit = useSubmit();
	const formRef = useRef<HTMLFormElement>(null);
	const name = `${user.firstName} ${user.lastName}`;
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
						to={`/users/${user.id}`}
						// this is for progressive enhancement
						onClick={(e) => e.preventDefault()}
						className="flex items-center gap-2"
					>
						<span className="text-body-sm font-bold">{name}</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.id}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault();
							submit(formRef.current);
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	);
}
